<script>
import Layout from "./../layouts/main";
import Swal from "sweetalert2";

import botForm from "@/pages/bot-form-modal";
import Bot from "@/state/entities/bot";
import UpdateBotFormModal from "@/pages/update-bot-form-modal";
import {localeComputed} from "@/state/helpers";
import spotStrategy1 from "@/pages/create-bot/spot-strategy-1";
import spotStrategy2 from "@/pages/create-bot/spot-strategy-2";
import {PredefinedStrategies} from "@/state/enum/predefined-strategies.ts";

export default {
  data() {
    return {
      openModal: false,
      predefine_strategy: PredefinedStrategies.conservative,
      rows: [],
    };
  },
  components: {
    UpdateBotFormModal,
    Layout,
    botForm,
    spotStrategy1,
    spotStrategy2,
    ...localeComputed
  },
  methods: {
    hideForm() {
      this.$emit('close-form-modal');
    },
    currentLocale() {
      return this.$i18n.locale;
    },
    openEditForm(bot_id) {
      this.$refs.updateBotForm.initialize(bot_id)
    },
    enable(bot) {
      Bot.api().enable(bot._id).then(() => {
        Swal.fire(this.$t("t-bot-successfully-enabled"), this.$t("t-bot-enabled-note-message"), "success");
      }).catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message === "Subscription expired") {
            Swal.fire("Subscription is inactive.", this.$t("t_inactive_subscription_message"), "warning").then(() => {
              /** @TODO hack: can't prevent toggle changes. status not changed but on UI changes affected  */
              window.location.reload()
            });
          }
          if (err.response.data.message === "Limit active bots") {
            Swal.fire("Active bots limited.", this.$t("t_bots_limit_reached_message"), "warning").then(() => {
              /** @TODO hack: can't prevent toggle changes. status not changed but on UI changes affected  */
              window.location.reload()
            });
          }
        } else {
          Swal.fire("System error.", "Something went wrong.", "warning").then(() => {
            /** @TODO hack: can't prevent toggle changes. status not changed but on UI changes affected */
            window.location.reload()
          });
        }
      })
    },
    disable(bot) {
      Bot.api().disable(bot._id)
    },
    toggleBot(bot) {
      if (bot.enabled === true) {
        this.disable(bot);
      } else {
        this.enable(bot);
      }
    },
  },
  computed: {
    bots() {
      return Bot.query().with(["exchange_connection"]).orderBy('created_at', 'desc').all();
      // return Bot.all().reverse();
    },
    PredefinedStrategies() {
      return PredefinedStrategies;
    },
  },
  async beforeMount() {
    (await Bot.api().get('/bot'));
  },
};
</script>
<template>
  <Layout>
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div class="card-body">

            <div class="d-flex align-items-center mb-4">
              <h5 class="card-title flex-grow-1 mb-0">{{ $t("t-bot-predefined-settings") }}</h5>
              <div class="live-preview">
                <b-modal v-model="openModal"
                         hide-footer
                         id="add-bot"
                         class="modal fade"
                         role="dialog"
                         @hidden="$refs.botForm.clearForm()"
                         :title='$t("t-create-bot")'>
                  <bot-form ref="botForm" :predefine_strategy="predefine_strategy" @created="openModal = false"></bot-form>
                </b-modal>
              </div>
            </div>

            <p class="text-muted">
              {{ $t("t-bot-intro-about-strategy") }} - <a href="/faqs">{{ $t("t-faqs") }}</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show" role="alert">
          <i class="las la-info-circle label-icon"></i>
          Currently we are working with following assets:
          <br>
          <strong>
          FLOW, ADA, DOT, BTC, MATIC, TWT,
          ATOM, APT, NEAR, LINK, LTC, ETH, SOL, UNI, AVAX, GALA
          </strong>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-xl-6 mt-3">
        <spot-strategy1 v-on:select-strategy="this.predefine_strategy = PredefinedStrategies.conservative"></spot-strategy1>
      </div>
      <div class="col-xs-12 col-xl-6 mt-3">
        <spot-strategy2  v-on:select-strategy="this.predefine_strategy = PredefinedStrategies.moderate"></spot-strategy2>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-xs-12" v-if="currentLocale() === 'en'">
        <div class="card">
          <div class="card-body">
            <h4 class="text-center">Risk Money Management</h4>
            <p>
              Trading on all markets consists of risks. Cryptocurrency is considered as risk assets.
              Due to high volatility on crypto markets trading there can bring you higher profit
              compared to low-risk assets and also increase risks. Therefore it is very important to
              follow Risk Money Management strategy.
            </p>
            <h5 class="alert alert-warning">
              General rules are:
            </h5>
            <ul>
              <li>Don’t risk more that you have (never trade for borrowed money);</li>
              <li>Don’t trade for 100% of your deposit;</li>
              <li>
                On Futures and options there is risk to lose all your balance.
                Always trade with Stop Loss and Take profit targets.
                So if market goes against your predictions, you will loose only planned capital;</li>
              <li>Take Profit should be always higher than Stop Loss.</li>
            </ul>
            <h5 class="alert alert-info">Further recommendations for trading on our platform:</h5>
            <p>
              Our further recommendation is to divide your trading balance between spot and futures with 70/30 or 80/20 ratio. Also, bear in mind that our strategies are developed based on regular market behaviour.
              It may happen that market changes its direction rapidly (unexpected news, institutions bankruptcy etc.).
            </p>
            <h5 class="alert alert-danger">Consequences</h5>
            <p>
              For Spot market: your open positions may hang until market will return to previous level
              and no new trades will be opened while there are no available slots.
              To avoid such cases you may keep part of your spot balance away from trading
              and open new slots on bot after new low was confirmed, so bot will continue
              to trade on lower market level.
              <br>
              For Futures market: all your open positions will be closed by Stop Loss orders,
              meaning you will lose part of your balance.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12" v-if="currentLocale() === 'ru'">
        <div class="card">
          <div class="card-body">
            <h4 class="text-center">Рисковое управление капиталом</h4>
            <p>
              Торговля на всех рынках связана с рисками. Криптовалюта считается рисковым активом.
              Из-за высокой волатильности торговля на криптовалютных рынках может принести вам
              более высокую прибыль по сравнению с активами с низким уровнем риска,
              а также увеличить риски. Поэтому очень важно использовать Управление Финансовыми Рисками.
            </p>
            <h5 class="alert alert-warning">
              Общие правила:
            </h5>
            <ul>
              <li>Не рискуйте больше, чем у вас есть (никогда не торгуйте заемными деньгами);</li>
              <li>Не торгуйте на 100% вашего депозита;</li>
              <li>
                На фьючерсах и опционах есть риск потерять весь свой баланс.
                Всегда торгуйте с целями Stop Loss и Take Profit.
                Поэтому, если рынок пойдет против ваших прогнозов, вы потеряете только
                запланированный капитал.;
              </li>
              <li>Take Profit всегда должен быть выше Stop Loss.</li>
            </ul>
            <h5 class="alert alert-info">Дальнейшие рекомендации относительно торговли на нашей платформе:</h5>
            <p>
              Наша дальнейшая рекомендация – разделить торговый баланс между спотом и
              фьючерсом в соотношении 70/30 или 80/20. Кроме того, имейте в виду,
              что наши стратегии разрабатываются на основе обычного поведения рынка.
              Может случиться так, что рынок быстро изменит свое направление
              (неожиданные новости, банкротство учреждений и т.д.).
            </p>
            <h5 class="alert alert-danger">Последствия</h5>
            <p>
              Для спотового рынка: ваши открытые позиции могут зависнуть до тех пор,
              пока рынок не вернется на прежний уровень, и новые сделки не будут открываться,
              пока нет свободных слотов. Чтобы избежать таких случаев, вы можете оставить часть
              своего спотового баланса вне торговли и открыть новые слоты на боте после подтверждения
              нового минимума, чтобы бот продолжал торговать на более низком уровне рынка.
              <br>
              Для рынка фьючерсов: все ваши открытые позиции будут закрыты ордерами Stop Loss,
              то есть вы потеряете часть своего баланса.
            </p>
          </div>
        </div>
      </div>
    </div>

    <update-bot-form-modal ref="updateBotForm"></update-bot-form-modal>

  </Layout>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>

